body {
  font-family: var(--default-font-family);
  font-weight: var(--font-regular);
  font-size: var(--default-font-size);
  line-height: var(--default-line-height);
  background: var(--body-bg);
  color: var(--body-color);
  margin: 0;
  position: relative;
  height: 100%;

  h1 {
    font-size: 28px;
    font-weight: var(--font-semi-bold);
  }

  h2 {
    font-size: 24px;
    font-weight: var(--font-semi-bold);
  }

  h3 {
    font-size: 20px;
    font-weight: var(--font-semi-bold);
  }

  .filter-card {
    .ant-form-item {
      margin: 0;
      font-size: 12px;

      .ant-form-item-label {
        padding: 0 0 3px;

        label {
          font-size: 12px;
          opacity: 0.75;
        }
      }

      .ant-input {
        font-size: 13px;
      }
    }
  }

  .btn-back {
    font-size: 0.8em;
    cursor: pointer;
    margin-right: 8px;
    color: var(--primary-color);
  }
}
