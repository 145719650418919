.ant-form {
  .ant-form-item {
    margin-bottom: 25px;

    .ant-form-item-label {
      padding: 0 0 5px;
    }

    input[type="file"] {
      font-size: 14px;
    }

    .ant-input {
      box-sizing: border-box;
      margin: 0;
      color: rgba(0, 0, 0, 0.88);
      font-size: 14px;
      list-style: none;
      position: relative;
      display: inline-block;
      width: 100%;
      min-width: 0;
      background-color: #fff;
      background-image: none;
      border-width: 1px;
      border-style: solid;
      border-color: #d9d9d9;
      border-radius: 6px;
      transition: all 0.2s;
      line-height: 30px;
      padding: 4px 10px;

      &:focus,
      &:hover {
        border-color: var(--primary-color);
        box-shadow: none;
      }

      &.ant-input-borderless {
        border: none;
        padding: 0;
      }
    }

    .ant-picker {
      @extend .ant-input;

      &.ant-picker-focused {
        border-color: var(--primary-color);
        box-shadow: none;
      }
    }

    .ant-select {
      @extend .ant-input;
      height: 40px;

      .ant-select-selector {
        padding: 0;
        border: none;

        .ant-select-selection-search {
          inset-inline-start: 0px;
          inset-inline-end: 17px;
        }
      }

      &.ant-select-focused {
        .ant-select-selector {
          box-shadow: none !important;
          border-color: transparent;
        }
      }
    }

    .ant-input-affix-wrapper {
      padding: 0 15px 0 0;
      background: transparent;
      border-radius: 6px;

      .ant-input-prefix {
        margin-inline-start: 10px;
        opacity: 0.4;
      }

      .ant-input {
        border-color: transparent;
      }
    }

    .ant-form-item-explain {
      font-size: 10px;
      text-align: left;
    }

    &.form-actions {
      display: flex;
      justify-content: space-between;

      .ant-btn {
        height: 40px;
        min-width: 122px;
        font-weight: var(--font-medium);
      }
    }
  }
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: var(--primary-color);
  box-shadow: none;
}
