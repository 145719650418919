.analysis-drawer {
  .ant-space {
    width: 100%;

    .ant-divider-horizontal {
      margin: 15px 0;
    }
  }

  &__summary {
    &__item {
      background: var(--text-gray-200);
      padding: 15px;
      margin-bottom: 10px;
      margin-top: 10px;

      h2 {
        font-size: 14px;
      }

      span {
        font-size: 18px;
        font-weight: var(--font-semi-bold);
      }
    }
  }

  &__data {
    &__item {
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid var(--text-gray-300);

      h2 {
        font-size: 15px;
        font-weight: var(--font-semi-bold);
      }

      &__options {
        margin-top: 3px;
        margin-top: 8px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
