.table-container {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 13px;
  }
}

.ant-table-wrapper {
  .ant-table {
    table {
      th,
      td {
        padding: 12px 8px;
      }

      th {
        font-size: 12px;
        font-weight: var(--font-semi-bold);
      }

      td {
        font-size: 13px;
      }
    }
  }

  .ant-pagination {
    justify-content: center !important;
  }
}

.app-table {
  width: 100%;
  caption-side: bottom;
  border-collapse: collapse;
  border-color: var(--text-gray-800);

  th,
  td {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    text-align: left;
    padding: 5px 10px;
    height: 40px;
  }

  thead {
    border-color: inherit;
    border-style: solid;
    border-width: 0;

    tr {
      th {
        text-align: -webkit-match-parent;
        background: var(--text-gray-300);
      }
    }
  }

  tbody {
    tr {
      td {
        border-bottom: 1px solid var(--text-gray-300);
      }

      &.app-table__tr-selected {
        td {
          border-bottom: 0;
        }
      }

      &.app-table__tr-child {
        & > td {
          padding-top: 0;
        }
      }
    }
  }
}
