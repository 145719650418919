@import "../../assets/styles/variables";

.login-page {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .login-form {
    max-width: 90%;
    margin-top: 25px;
    margin-bottom: 25px;

    @media (max-width: 576px) {
      max-width: 100%;
    }

    .login-form-header {
      text-align: center;
      margin-bottom: 17px;

      img {
        width: 100%;
        margin-bottom: 35px;
      }

      h1 {
        color: inherit;
        font-size: 32px;
        margin: 0;
      }

      h3 {
        color: inherit;
        font-size: 15px;
        font-weight: var(--font-medium);
        margin: 0;
        margin-top: 8px;
      }
    }

    form {
      width: 450px;
      max-width: 100%;
      background: var(--white);
      padding: 35px;
      border-radius: 15px;

      @media (max-width: 576px) {
        border-radius: 0;
      }

      .ant-form-item {
        .ant-input {
          font-size: 14px;
        }

        .ant-form-item-explain {
          position: relative;
          top: auto;
        }

        &.form-actions {
          margin: 0;

          .ant-form-item-row {
            width: 100%;
          }

          .ant-btn {
            font-size: 14px;
            text-transform: uppercase;
            height: 45px;
          }
        }
      }

      .form-forgot-password {
        font-size: 14px;
        text-align: right;
        margin-top: 15px;

        a {
          color: var(--text-gray-600);

          &:hover {
            color: var(--text-gray-800);
          }
        }
      }

      .form-register {
        font-size: 14px;
        text-align: center;
      }
    }
  }

  .select-language-container {
    margin-top: 13px;

    @media (max-width: 576px) {
      margin-right: 35px;
      margin-left: 35px;
    }

    .ant-dropdown-trigger {
      color: var(--text-gray-600);
      font-size: 13px;
      @extend %noselect;

      .current-language {
        color: var(--body-color);
        display: inline-block;
        width: 70px;
      }

      .anticon {
        color: var(--body-color);
      }
    }
  }

  .select-language-dropdown {
    padding-top: 5px;

    .ant-dropdown-menu {
      width: 100px;
      float: right;

      .ant-dropdown-menu-item {
        text-align: right;
      }
    }
  }

  &.mfa-page {
    .otp-inputs {
      .ant-input {
        width: 45px !important;
      }

      span {
        display: inline-block;
        padding: 0 7px;
        opacity: 0.2;
      }
    }

    .re-request {
      text-align: right;
      font-size: 14px;
      margin-top: 10px;

      .ant-btn {
        padding: 0;
      }
    }
  }

  .ant-result {
    position: relative;
    margin-left: var(--margin-left);

    .ant-result-title {
      font-size: 40px;
      letter-spacing: 5px;
      text-transform: uppercase;
    }

    .ant-result-subtitle {
      font-size: 15px;
    }

    .ant-result-extra {
      display: flex;
      justify-content: center;

      a {
        width: 130px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        color: #fff;
      }
    }
  }
}
