.auto-call-form-page {
  .play-btn-container {
    position: relative;

    .ant-btn {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      position: absolute;
      right: 8px;
      top: -2px;
      z-index: 99;
      font-size: 14px;
    }
  }

  .choose-audio-file-label {
    input[type="file"] {
      display: none;
    }

    .choose-file-btn {
      cursor: pointer;
      padding: 7px 9px;
      border-radius: 5px;
      background: #eee;

      &:hover {
        background: #ddd;
      }
      display: inline-block;
    }

    span {
      display: inline-block;
      margin-left: 15px;
    }
  }
}
