.ant-btn {
  // height: 40px;
  margin: 0;

  &.ant-btn-default {
    &:hover {
      color: var(--primary-color);
      border-color: var(--primary-color);
    }
  }

  &.ant-btn-primary {
    background: var(--primary-color);

    &:hover {
      background: var(--primary-color-hover);
    }
  }

  &:disabled,
  &[disabled] {
    opacity: 0.6;
    color: inherit;
    pointer-events: none;

    &:hover {
      border-color: #d9d9d9;
      color: rgba(0, 0, 0, 0.25);
      background-color: rgba(0, 0, 0, 0.04);
      box-shadow: none;
    }
  }
}

.link,
a {
  padding: 0;
  height: auto;
  color: var(--primary-color);

  &:not(:disabled):hover {
    color: var(--primary-color-hover);
  }
}
