.ant-form.settings-list {
  .ant-form-item {
    .ant-form-item-label {
      height: 40px;
      padding: 0;
      display: flex;
      align-items: center;
      font-weight: bold;
    }

    .ant-input {
      height: 40px;
    }

    .is-confirmed {
      font-size: 14px;
      margin-top: 5px;
      width: 100%;
    }

    .ant-form-item-control-input {
      display: flex;
      width: 100%;

      &__action {
        min-width: 80px;
        margin-left: 10px;

        .ant-btn {
          height: 40px;
          font-size: 14px;
        }
      }

      .ant-form-item-control-input-content {
        position: relative;

        .ant-input-suffix {
          position: absolute;
          right: 12px;
          top: 7px;
        }
      }
    }
  }
}
