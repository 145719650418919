.dashboard-page {
  ul.dashboard-news-lists {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin-bottom: 20px;

      h1 {
        font-size: 16px;
        font-weight: var(--font-semi-bold);
        margin-bottom: 5px;

        small {
          display: inline-block;
          font-weight: var(--font-medium);
          padding-left: 10px;
        }
      }

      p {
        font-size: 14px;
      }
    }
  }

  .dashboard-usage-card {
    .ant-card-body {
      h1 {
        font-size: 16px;
        font-weight: var(--font-semi-bold);
        margin-bottom: 10px;
      }
    }
  }
}
