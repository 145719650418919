.ant-modal-root {
  .ant-modal {
    .ant-modal-content {
      .ant-modal-header {
        margin-bottom: 20px;

        .ant-modal-title {
          font-size: 18px;
        }
      }

      .ant-modal-footer {
        display: flex;
        justify-content: space-between;

        .ant-btn {
          font-size: 14px;
          height: 32px;
          padding: 4px 15px;
          border-radius: 6px;
        }
      }
    }
  }
}
