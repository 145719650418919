.app-layout {
  background: transparent;

  .app-container {
    width: 100%;
    max-width: 1320px;
    padding-right: calc(3rem * 0.5);
    padding-left: calc(3rem * 0.5);
    margin: 0 auto;
    clear: both;

    @media (max-width: 1200px) {
      max-width: 1140px;
    }

    @media (max-width: 992px) {
      max-width: 960px;
    }

    @media (max-width: 768px) {
      max-width: 720px;
    }

    @media (max-width: 576px) {
      max-width: 540px;
    }
  }

  .app-footer {
    text-align: center;
    display: none;
  }

  .app-header {
    padding-inline: 0;

    .app-container {
      height: 100%;
    }

    .app-logo {
      color: var(--white);
      font-size: 20px;
      font-weight: var(--font-bold);
      float: left;
      margin-right: 10px;
      display: block;

      img {
        height: 35px;
      }
    }

    .app-menu {
      width: 100%;

      &.ant-menu {
        border-bottom: none;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: calc(100% - 25px);
        background: transparent;

        .ant-menu-item {
          text-transform: uppercase;
          font-weight: var(--font-regular);
          font-family: var(--default-font-family);
          color: var(--text-gray-400);
          font-size: 15px;
          line-height: 30px;
          padding-inline: 15px;

          &::after {
            border-width: 0;
            border-bottom-color: var(--white);
            inset-inline: 15px;
          }

          &:hover {
            color: var(--white);
          }

          &.ant-menu-item-selected {
            color: var(--white);
            &::after {
              border-width: 1px;
            }
          }

          .logout-menu-item {
            display: flex;
            align-items: center;

            .anticon {
              margin-right: 3px;
            }
          }
        }
      }

      &__item {
        text-transform: uppercase;
        font-weight: 300;
        font-family: var(--default-font-family);
        font-size: 15px;
        line-height: 40px;

        &::after {
          border-width: 1px;
        }

        &:hover {
          &::after {
            border-width: 1px;
            border-bottom-color: var(--primary-color);
          }
        }

        &__logout {
          display: none;
          &:hover {
            &::after {
              border-bottom: none;
            }
          }
        }
      }
    }

    .user-menu {
      width: 42px;
      height: 40px;
      background: #0f5dd3;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      margin-left: 10px;
      cursor: pointer;
    }
  }

  .main-content-layout {
    margin: 30px 0;
  }
}

.user-dropdown {
  .ant-dropdown-menu {
    padding: 0;
    min-width: 150px;
    overflow: hidden;

    .ant-dropdown-menu-item {
      border-radius: 0;
      padding: 10px 15px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      &:last-child {
        border-bottom: 0;
      }

      .ant-dropdown-menu-title-content {
        a {
          font-weight: 500;
        }
      }
    }

    .logout-btn {
      color: red;
    }
  }

  &__name {
    font-size: 14px;
    font-weight: bold;
    border-radius: 0;
    padding: 10px 15px;
  }
}
