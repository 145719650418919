.phone-book-page {
  .table-container {
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      font-size: 13px;

      &__actions {
        .ant-btn {
          // height: 35px;
        }
      }
    }

    .ant-table-wrapper {
      .ant-table {
        table {
          td {
            .ant-btn {
              min-width: 30px;
              height: 30px;
              padding: 0;
            }
          }
        }
      }
    }
  }
}

.phone-book-bulk-upload-modal {
  .ant-form {
    .ant-form-item {
      &.form-actions {
        border-top: 1px solid #ddd;
        margin-bottom: 8px;
        padding-top: 25px;

        .ant-btn {
          height: 38px;
        }
      }
    }
  }
}
