.questionnaire-form-page {
  .ant-form-item-label {
    label {
      height: 25px;
      font-size: 14px;
      font-weight: var(--font-semi-bold);
    }
  }

  .question-radio {
    margin-right: 5px;

    span {
      font-size: 14px;
      padding-left: 4px;
      padding-right: 10px;
    }
  }

  .question-checkbox {
    border: 1px solid #333;
    margin-right: -1px;
    position: relative;
    width: 25px;
    height: 24px;
    display: inline-block;
    overflow: hidden;

    &:last-child {
      margin-right: 0;
    }

    span {
      left: 0;
      width: 100%;
      line-height: 24px;
      font-size: 14px;
      text-align: center;
      position: absolute;
      cursor: pointer;
    }

    input {
      display: none;

      &:hover + span {
        background: #ddd;
      }

      &:disabled + span {
        opacity: 0.5;
        pointer-events: none;
      }

      &:checked + span {
        background: #1677ff;
        color: #fff;
      }
    }
  }

  .play-btn-container {
    display: flex;
    justify-content: flex-end;
    padding-right: 4px;

    .ant-btn {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .question-collapse {
    .ant-collapse-header {
      .ant-collapse-header-text {
        font-weight: var(--font-semi-bold);
        text-transform: uppercase;
      }
    }
  }

  .ant-collapse-borderless {
    background-color: rgba(113, 39, 39, 0.02);
    border: 0;
  }
}
