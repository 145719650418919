.ant-card {
  &.content-card {
    border-radius: 8px;

    .ant-card-head {
      .ant-card-head-wrapper {
        .ant-card-head-title {
          font-size: 22px;
          font-weight: var(--font-bold);

          em {
            display: inline-block;
            padding-left: 10px;
            font-size: 13px;
            font-weight: var(--font-medium);
          }
        }
      }
    }
  }
}
